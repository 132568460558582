<template>
    <v-expansion-panels v-model="isExpand">
        <v-expansion-panel>
            <v-expansion-panel-header :class="titleColor">
                {{ title }}
                <template v-slot:actions>
                    <v-icon :color="iconColor">{{ icon }}</v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-alert border="left" colored-border type="info" elevation="5">
                    <v-container grid-list-xl>
                        <v-layout wrap>
                            <v-flex xs12 md6 lg8 class="py-0">
                                <strong>Localização: </strong>
                                {{ this.charge.point && this.charge.point.city ? this.charge.point.city : "" }}
                            </v-flex>
                            <v-flex xs12 md6 lg4 class="py-0"><strong>Modelo: </strong>{{ this.charge.model }} </v-flex>
                            <v-flex xs12 md6 lg4 class="py-0" v-if="this.charge.data"><strong>Última comunicação: </strong>{{ this.charge.data.keepAlive }}</v-flex>
                            <v-flex xs12 md6 lg4 class="py-0"><strong>Data Cadastro: </strong>{{ this.charge.createdIn }} </v-flex>
                            <v-flex xs12 md6 lg4 class="py-0" v-if="this.charge.board"><strong>Gateway: </strong>{{ this.charge.board.name }} </v-flex>
                            <v-flex xs12 md6 lg4 class="py-0" v-if="this.charge.board"><strong>Versão: </strong>{{ this.charge.board.version }}</v-flex>
                            <v-flex xs12 md6 lg4 class="py-0" v-if="this.charge.board"><strong>Conexão: </strong>{{ this.charge.board.connection }} </v-flex>
                            <v-flex xs12 md8 lg4 class="py-0"><strong>Identificador: </strong>{{ this.charge.UUID }}</v-flex>
                        </v-layout>
                    </v-container>

                    <v-divider class="info" style="opacity: 0.22" />

                    <v-layout wrap v-if="charge.data && charge.data.lastNfc">
                        <v-flex xs12 class="text-right">
                            <v-btn x-small color="success" class="ma-2 white--text" @click="copy(charge.data.lastNfc)">
                                NFC: {{ charge.data.lastNfc }}
                                <v-icon right dark small> content_copy </v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>

                    <v-layout wrap v-if="charge.protocol == '1.6'">
                        <v-flex xs12 class="text-right">
                            <strong>OCPP 1.6: </strong>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="reset('Hard')"> Hard Reset </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="reset('Soft')"> Soft Reset </v-btn>
                        </v-flex>
                        <v-flex xs12 class="text-right" v-for="item in charge.carChargeData" :key="item.plug">
                            <strong>Plug {{ item.plug }}: </strong>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="changeAvailability('Inoperative', 1)"> Plug {{ item.plug }}: Inoperative </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="changeAvailability('Operative', 1)"> Plug {{ item.plug }}: Operative </v-btn>
                            <v-btn x-small color="error" class="ma-1 white--text" @click="unlockConnector(1)"> Plug {{ item.plug }}: Unlock Connector </v-btn>

                            <v-btn x-small color="blue-grey" class="white--text my-2 ml-4" :href="linkNowPlug(item.plug)" target="_blank" v-if="charge.name">
                                <v-icon left>link</v-icon>
                                NOW
                            </v-btn>
                        </v-flex>
                    </v-layout>

                    <v-container id="box" style="background-color: white" class="mx-auto rounded-xl white--text" fill-height fluid>
                        <v-row align="center" justify="center">
                            <v-col cols="12" md="6" lg="3">
                                <Command :UUID="charge.UUID" :IP="charge.vpnIp" :operate="charge.operate" v-if="charge.vpnIp" />
                            </v-col>
                            <v-col cols="12" md="6" lg="3">
                                <ChargerData :UUID="charge.UUID" :online="charge.data.online" />
                            </v-col>
                            <v-col cols="12" md="6" lg="3">
                                <v-btn small color="blue-grey" class="white--text" style="width: 100%" :href="vpnHref" target="_blank" v-if="charge.vpnIp">
                                    <v-icon left>vpn_lock</v-icon>
                                    VPN: {{ charge.vpnIp }}
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="6" lg="3">
                                <v-btn small color="blue-grey" class="white--text" style="width: 100%" :href="linkNow" target="_blank"
                                    v-if="charge.name && charge.protocol != '1.6'">
                                    <v-icon left>link</v-icon>
                                    NOW
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-alert>
                <ConfirmModal ref="confirm" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";
import ChargerData from "./ChargerData";
import Command from "@/components/shared/Command";
import statusCharger from "@/helpers/StatusCharger";

export default {
    props: {
        charge: {
            type: Object,
            required: true,
        },
    },

    components: {
        ChargerData,
        Command,
        ConfirmModal,
    },

    data() {
        return {
            statusCharger,
            isExpand: undefined,
            visivel: true,
        };
    },

    methods: {
        copy(text) {
            navigator.clipboard.writeText(text);
            this.$eventHub.$emit("msgSuccess", `Código copiado!!!`);
        },
        sendCommand(url, parameters) {
            this.$http
                .postAlternativeAPI(url, parameters)
                .then((result) => {
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Enviado com sucesso.");
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar, tente novamente.");
                });
        },
        unlockConnector(plug) {
            this.$refs.confirm.open("Confirma?", "Deseja realmente desbloquear o carregador?").then((confirm) => {
                if (confirm) this.sendCommand("api/v2/ocpp/unlockConnector", { charger: this.charge.id, plug: plug });
            });
        },
        changeAvailability(type, plug) {
            this.$refs.confirm.open("Confirma?", "Deseja realmente alterar disponibilidade do carregador?").then((confirm) => {
                if (confirm) this.sendCommand("api/v2/ocpp/changeAvailability", { charger: this.charge.id, plug: plug, type: type });
            });
        },
        reset(type) {
            this.$refs.confirm.open("Confirma?", "Deseja realmente resetar o carregador?").then((confirm) => {
                if (confirm) this.sendCommand("api/v2/ocpp/reset", { charger: this.charge.id, type: type });
            });
        },
        linkNowPlug(plug) {
            return "https://incharge.app/now/" + this.charge.name + "/" + plug;
        },
    },

    computed: {
        title() {
            let pointName = this.charge.point ? this.charge.point.name : "";
            let chargerName = this.charge.name ? " '" + this.charge.name + "' " : "";

            let status = "";
            if (this.charge.data.online === 1) {
                for (let i in this.charge.carChargeData) {
                    let carData = this.charge.carChargeData[i];
                    status += `[${this.statusCharger.getTranslated(carData.status)}]`;
                }
            }

            return `${pointName} ${chargerName} ${status}`;
        },
        titleColor() {
            return this.charge.data.online === 1 ? "success--text" : this.charge.data.server == 1 ? "warning--text" : "error--text";
        },
        iconColor() {
            return this.charge.data.online === 1 ? "success" : this.charge.data.server == 1 ? "warning" : "error";
        },
        icon() {
            return this.charge.data.online === 1 ? "done" : this.charge.data.server == 1 ? "warning" : "error";
        },
        vpnHref() {
            return "http://" + this.charge.vpnIp;
        },
        linkNow() {
            return this.charge.bikeChargerConfiguration ? "https://incharge.app/ebike/" + this.charge.name : "https://incharge.app/now/" + this.charge.name + "/1";
        },
    },
};
</script>